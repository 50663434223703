import { ReactSVG } from "react-svg";
import reporticon from "../../../../assets/images/report-icon.svg";
import threedotvertical from "../../../../assets/images/three-dots-vertical.svg";
import editicon from "../../../../assets/images/edit-icon.svg";
import deleteicon from "../../../../assets/images/delete-icon.svg";
import { Dropdown, Table } from "react-bootstrap";

import detailicon from "../../../../assets/images/detail-icon.svg";
import { useEffect, useState } from "react";
import useInventory from "../../../../Hooks/useInventory";
import Loading from "../../../../components/LoadingPage/Loading";
import { EditOrderManageDetailModel } from "./popUp/EditOrderMDModel";
import useLocations from "../../../../Hooks/useLocations";
import { toggleClass } from "../../../../utils/commonUtils";
import { selectCurrentUserId } from "../../../../redux/auth/authSlice";
import { useAppSelector } from "../../../../redux/hooks";
import { DeleteConfModel } from "../../../../components/DeleteConfModel/deleteConfModel";
import React from "react";
export const OrderManagementTab = ({
  toggleOrderMngtDetailModal,
  selectedItem,
  setSelectedOrdManItem,
  invManageData,
}: any) => {
  const { getLocationsAPI, getLocationsLoading } = useLocations();
  const [locationDropDownVal, setLocationDropDownVal] = useState([]);
  const [showOrderManagementEditModel, SetShowOrderManagementEditModel] =
    useState(false);
  const [inventoryOrderLevelData, setInventoryOrderLevelData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const currentUserId = useAppSelector(selectCurrentUserId);
  const [currentRecord, setCurrentRecord] = useState<any>();
  const { inventoryOrderLevelEditApi, inventoryOrderLevelLoadingEdit } =
    useInventory();
  const { inventoryOrderLevelDeleteApi, inventoryOrderLevelLoadingDelete } =
    useInventory();
  const getOMList = () => {
    inventoryOrderManagementGet(selectedItem.id).then((res: any) => {
      if (res) {
        setInventoryOrderLevelData(res);
      }
    });
  };
  const OMEdit = (values: any) => {
    inventoryOrderLevelEditApi(values)
      .then(() => {
        EditOrderMDModel();
        getOMList();
      })
      .catch();
  };
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };

  const { inventoryOrderManagementGet, inventoryOrderManagementLoading } =
    useInventory();
  useEffect(() => {
    if (selectedItem?.orderingProductMasterId) {
      if (invManageData?.length > 0) getOMList();
      getLocationsAPI({ searchText: "", includeDeleted: false })
        .then((res: any) => {
          setLocationDropDownVal(
            res?.map((option: any) => {
              return {
                value: option.id,
                label: option.locationName,
              };
            }),
          );
        })
        .catch();
    }
  }, [selectedItem, invManageData]);

  const deleteOMRecord = () => {
    inventoryOrderLevelDeleteApi(selectedItem.id).then((res: any) => {
      if (res) {
        setInventoryOrderLevelData(res);
      }
    });
  };
  const EditOrderMDModel = () => {
    toggleClass();
    SetShowOrderManagementEditModel(!showOrderManagementEditModel);
  };
  return (
    <>
      {inventoryOrderManagementLoading ||
      getLocationsLoading ||
      inventoryOrderLevelLoadingEdit ||
      inventoryOrderLevelLoadingDelete ? (
        <Loading />
      ) : null}
      <div className="inventory-tab-head">
        <h4>
          Displaying {inventoryOrderLevelData?.length || 0} of{" "}
          {inventoryOrderLevelData?.length || 0} Results
        </h4>
        <div className="inventory-tab-action-wrapper">
          <div className="inventory-tab-action-box inventory-tab-action-trans-report">
            <ReactSVG className="svg-box" wrapper="span" src={reporticon} />{" "}
            Order Report
          </div>
          {/* <div className="inventory-tab-action-box inventory-tab-action-filter">
            <ReactSVG className="svg-box" wrapper="span" src={filtericon} />
          </div> */}
        </div>
      </div>
      <div className="inventory-profile-table-wrapper">
        <Table bordered={false} hover={true}>
          <thead>
            <tr>
              <th>LOCATION</th>
              <th>Reorder Level</th>
              <th>Reorder Quantity</th>
              <th>QUANTITY ON HAND</th>
              <th>Quantity on order</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {inventoryOrderLevelData?.map((x: any) => (
              <tr key={x.locationId}>
                <td>{x.locationName}</td>
                <td>{x.reorderLevel || "-"}</td>
                <td>{x.reorderQty || "-"}</td>
                <td ref={(el) => {
                      if (el && x?.color) {
                        el.style.setProperty('color', x?.color.toLowerCase(), 'important');
                      }
                    }}>{x.qtyOnHand || "-"}</td>
                <td>{x.orderQty || "-"}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <ReactSVG
                        className="svg-box"
                        wrapper="span"
                        src={threedotvertical}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          setCurrentRecord(x);
                          SetShowOrderManagementEditModel(true);
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={editicon}
                        />
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCurrentRecord(x);
                          toggleModal();
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={deleteicon}
                        />
                        Delete
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setSelectedOrdManItem(x);
                          toggleOrderMngtDetailModal();
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={detailicon}
                        />
                        Details
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {showOrderManagementEditModel && (
        <EditOrderManageDetailModel
          locationDropDownVal={locationDropDownVal}
          currentRecord={currentRecord}
          currentUserId={currentUserId}
          toggleEditOrderMDModel={EditOrderMDModel}
          OMEdit={OMEdit}
          selectedItemName={selectedItem.orderingProductMaster}
          selectedItemId={selectedItem.id}
        />
      )}
      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteOMRecord}
        modelName={`record`}
        recordName={currentRecord?.locationName || ""}
      />
    </>
  );
};
