import { Button, Form, Modal } from "react-bootstrap";
import DatePicker from "react-date-picker";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import useInventory from "../../../../Hooks/useInventory";
import { dateFormatter } from "../../../../utils/commonUtils";
import Loading from "../../../../components/LoadingPage/Loading";

export const LookUpModel = ({
  show,
  handleClose,
  renderItemLookUpPage,
  setFormBarcodeValues,
}: any) => {
  const { getBarcodeDecode, barcodeDetailsLoading } = useInventory();

  const {
    handleChange: handleChangeBarcode,
    values: barcodeValues,
    handleSubmit: barcodeSubmit,
    touched: barcodeTouched,
    errors: barcodeErrors,
    resetForm: barcodeReset,
  } = useFormik({
    initialValues: renderItemLookUpPage,
    validationSchema: Yup.object({
      addNdcProductMaster: Yup.boolean(),
      checkInternet: Yup.boolean(),
      isMedicine: Yup.boolean(),
      useMfrBarcode: Yup.boolean(),
      barcode: Yup.string().required("Barcode is Required"),
    }),

    onSubmit: async (
      barcodeFormValues: any,
      { setSubmitting }: FormikHelpers<any>,
    ) => {
      try {
        const response: any = await getBarcodeDecode(barcodeFormValues);
        setValues({
          cvx: response.cvX_Model,
          expiryDate: dateFormatter(response.expirationDate) ?? "",
          lotNo: response.lotNumber,
          serialNumber: response.serialNumber || "NA",
          ndcCode: response.ndC_SKU,
          manufacturer: response.manufacturer,
          drugName: response.group,
          rxName: response.name,
          barcode: "",
        });
        if (barcodeFormValues.useMfrBarcode) {
          setFieldValue("barcode", response.barcode);
        }
      } catch (err: any) {}
      setSubmitting(false);
    },
    enableReinitialize: true,
  });
  const {
    handleChange,
    values,
    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      rxName: "",
      drugName: "",
      manufacturer: "",
      ndcCode: "",
      lotNo: "",
      cvx: "",
      expiryDate: "",
      serialNumber: "",
      barcode: "",
    },
    validationSchema: Yup.object({
      rxName: Yup.string().required("Item Name is Required"),
      drugName: Yup.string().required("Item Group is Required"),
      manufacturer: Yup.string().required("Manufacturer is Required"),
      ndcCode: Yup.string().required("NDC is Required"),
      lotNo: Yup.string().required("LOT is Required"),
      cvx: Yup.string().required("CVX/Model is Required"),
      // expiryDate: Yup.string().required("Expiration Date is Required"),
    }),
    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      try {
        setFormBarcodeValues(values);
        barcodeReset();
        resetForm();
        handleClose();
      } catch (err: any) {}
      setSubmitting(false);
    },
    enableReinitialize: true,
  });
  return (
    <Modal
      size="lg"
      id="lookupmodal"
      show={show}
      onHide={() => {
        resetForm();
        barcodeReset();
        handleClose();
      }}
    >
      {barcodeDetailsLoading && <Loading />}

      <Modal.Header closeButton>Look Up</Modal.Header>
      <Modal.Body>
        <div className="add-item-box">
          <Form onSubmit={barcodeSubmit}>
            <Form.Group className="mb-4 full-width">
              <div className="item-radio-content">
                <div className="item-status-box">
                  <Form.Check
                    inline
                    label="Use “2D Barcode” for Tracking"
                    type="checkbox"
                    id="useMfrBarcode"
                    name="useMfrBarcode"
                    onChange={handleChangeBarcode}
                    checked={barcodeValues.useMfrBarcode}
                  />
                </div>
                <div className="item-recall-box">
                  <div className="">
                    <Form.Check
                      inline
                      label="Check Internet"
                      name="checkInternet"
                      type="checkbox"
                      id="checkInternet"
                      onChange={handleChangeBarcode}
                      checked={barcodeValues.checkInternet}
                    />
                  </div>
                </div>
                <div className="item-recall-box">
                  <div className="">
                    <Form.Check
                      inline
                      label="Add to NDC/Product Master"
                      type="checkbox"
                      name="addNdcProductMaster"
                      id="addNdcProductMaster"
                      onChange={handleChangeBarcode}
                      checked={barcodeValues.addNdcProductMaster}
                    />
                  </div>
                </div>
                <div className="item-status-box">
                  <div className="">
                    <Form.Check
                      inline
                      label="Med"
                      type="radio"
                      name="isMedicine"
                      id="isMedicine"
                      onChange={(e) =>
                        handleChangeBarcode({
                          target: {
                            name: "isMedicine",
                            value: e.target.checked ? true : false,
                          },
                        })
                      }
                      checked={!!barcodeValues.isMedicine}
                    />
                    <Form.Check
                      inline
                      label="Non-Med"
                      type="radio"
                      onChange={(e) =>
                        handleChangeBarcode({
                          target: {
                            name: "isMedicine",
                            value: e.target.checked ? false : true,
                          },
                        })
                      }
                      checked={!!!barcodeValues.isMedicine}
                    />
                  </div>
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mb-4 full-width inline">
              <Form.Label>Barcode</Form.Label>
              <div className="barcodebox">
                <Form.Control
                  type="text"
                  placeholder="Enter Barcode"
                  id="barcode"
                  name="barcode"
                  onChange={handleChangeBarcode}
                  value={barcodeValues.barcode}
                  isInvalid={barcodeTouched.barcode && !!barcodeErrors.barcode}
                  autoFocus
                />

                <button type="submit">Decode</button>
              </div>
              {barcodeTouched.barcode && barcodeErrors.barcode ? (
                <Form.Control.Feedback type="invalid">
                  {barcodeErrors.barcode as string}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Item Name*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Item Name"
                id="rxName"
                name="rxName"
                onChange={handleChange}
                value={values.rxName}
                isInvalid={touched.rxName && !!errors.rxName}
              />
              {touched.rxName && errors.rxName ? (
                <Form.Control.Feedback type="invalid">
                  {errors.rxName as string}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Item Group*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Item Group"
                id="drugName"
                name="drugName"
                onChange={handleChange}
                value={values.drugName}
                isInvalid={touched.drugName && !!errors.drugName}
              />
              {touched.drugName && errors.drugName ? (
                <Form.Control.Feedback type="invalid">
                  {errors.drugName as string}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Manufacturer*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Manufacturer Name"
                id="manufacturer"
                name="manufacturer"
                onChange={handleChange}
                value={values.manufacturer}
                isInvalid={touched.manufacturer && !!errors.manufacturer}
              />
              {touched.manufacturer && errors.manufacturer ? (
                <Form.Control.Feedback type="invalid">
                  {errors.manufacturer as string}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>NDC*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter NDC"
                id="ndcCode"
                name="ndcCode"
                onChange={handleChange}
                value={values.ndcCode}
                isInvalid={touched.ndcCode && !!errors.ndcCode}
              />
              {touched.ndcCode && errors.ndcCode ? (
                <Form.Control.Feedback type="invalid">
                  {errors.ndcCode as string}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>LOT*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter LOT Number"
                id="lotNo"
                name="lotNo"
                onChange={handleChange}
                value={values.lotNo}
                isInvalid={touched.lotNo && !!errors.lotNo}
              />
              {touched.lotNo && errors.lotNo ? (
                <Form.Control.Feedback type="invalid">
                  {errors.lotNo as string}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>CVX/Model*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter CVX/Model"
                id="cvx"
                name="cvx"
                onChange={handleChange}
                value={values.cvx}
                isInvalid={touched.cvx && !!errors.cvx}
              />
              {touched.cvx && errors.cvx ? (
                <Form.Control.Feedback type="invalid">
                  {errors.cvx as string}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Expiration Date*</Form.Label>
              <div className="calendar-box">
                <DatePicker
                  format="MM/dd/yyyy"
                  openCalendarOnFocus={false}
                  className="form-control"
                  value={values.expiryDate}
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  showLeadingZeros={true}
                  onChange={(date) =>
                    handleChange({
                      target: { name: "expiryDate", value: date },
                    })
                  }
                  clearIcon={null}
                  calendarIcon={<i className="bi bi-calendar"></i>}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-4 full-width text-end">
              <Button
                onClick={() => {
                  resetForm();
                  barcodeReset();
                  handleClose();
                }}
                style={{ marginRight: "10px" }}
                variant="outline-primary"
              >
                Cancel
              </Button>
              <Button type="button" onClick={() => handleSubmit()}>
                Verified, Use Current Values
              </Button>
            </Form.Group>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};
