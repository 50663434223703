import dashboardicon from "../../assets/images/dashboard.svg";
import inventoryicon from "../../assets/images/inventory.svg";
import recordsicon from "../../assets/images/records.svg";
import athenatransicon from "../../assets/images/athena-trans-icon.svg";
import usericon from "../../assets/images/user.svg";
import inventorystatusicon from "../../assets/images/inventory-status-icon.svg";
import inventoryordericon from "../../assets/images/inventory-order-icon.svg";
import usagetransicon from "../../assets/images/usage-transactions-icon.svg";
import expinventoryicon from "../../assets/images/expired-inventory-icon.svg";
import receivedetailicon from "../../assets/images/receiving-details-icon.svg";
import usagedetailicon from "../../assets/images/usage-detail-report-icon.svg";
import transferdetailicon from "../../assets/images/transfer-details-report-icon.svg";
import settingicon from "../../assets/images/setting-icon.svg";
import apimsgicon from "../../assets/images/api-msg-icon.svg";
import userguideicon from "../../assets/images/userguide-icon.svg";
import locationmastericon from "../../assets/images/location-master-icon-2.svg";
import itemtypeicon from "../../assets/images/item-type-icon4.svg";
import unitmastericon from "../../assets/images/unit-mater-icon.svg";
import stockmastericon from "../../assets/images/stock-mater-icon.svg";
import vendormastericon from "../../assets/images/vendor-master-icon.svg";
import patientmastericon from "../../assets/images/patient-master-icon.svg";
import orderingproducticon from "../../assets/images/ordering-product-icon.svg";
import productmastericon from "../../assets/images/product-master-icon.svg";
import permissionicon from "../../assets/images/permission-icon.svg";
import itemwarningicon from "../../assets/images/item-warning-icon.svg";
import contactusicon from "../../assets/images/contact-us-icon.svg";
import predictionicon from "../../assets/images/prediction-icon.svg";
import forcasticon from "../../assets/images/forcast-icon.svg";
import ticketicon from "../../assets/images/tickets-icon.svg";
import aboutusicon from "../../assets/images/about-us-icon.svg";
import { ReactSVG } from "react-svg";
export interface IMenuItem {
  name: string | null;
  icon?: any | null;
  path?: string;
  subMenus?: Array<IMenuItem>;
  pages?: Array<{ name: string; path: string }>;
}

export const menuItems: IMenuItem[] = [
  {
    name: "Manage",
    icon: <ReactSVG className="svg-box" wrapper="span" src={dashboardicon} />,
    path: "",
    subMenus: [
      {
        name: "Dashboard",
        icon: (
          <ReactSVG className="svg-box" wrapper="span" src={dashboardicon} />
        ),
        path: "/",
      },

      {
        name: "Inventory",
        icon: (
          <ReactSVG className="svg-box" wrapper="span" src={inventoryicon} />
        ),

        path: "/inventory",
        pages: [
          {
            name: "Add Item",
            path: "/create-inventory",
          },
          {
            name: "View Item",
            path: "/view-inventory",
          },
          {
            name: "Edit Item",
            path: "/edit-inventory",
          },
        ],
      },
      {
        name: "Record Usage",
        icon: <ReactSVG className="svg-box" wrapper="span" src={recordsicon} />,
        path: "/records",
      },
      {
        name: "Athena Transactions",
        icon: (
          <ReactSVG className="svg-box" wrapper="span" src={athenatransicon} />
        ),
        path: "/athena-transaction",
        pages: [
          {
            name: "Order Details",
            path: "/view-transaction/:id",
          },
          {
            name: "Edit",
            path: "/edit-transaction/:id",
          },
        ],
      },
    ],
  },
  {
    name: "Reports",
    icon: (
      <ReactSVG className="svg-box" wrapper="span" src={inventorystatusicon} />
    ),
    path: "",
    subMenus: [
      {
        name: "Inventory Status",
        icon: (
          <ReactSVG
            className="svg-box"
            wrapper="span"
            src={inventorystatusicon}
          />
        ),
        path: "/inventory-status-report",
      },
      {
        name: "Expired Inventory",
        icon: (
          <ReactSVG className="svg-box" wrapper="span" src={expinventoryicon} />
        ),
        path: "/inventory-expire-report",
      },
      {
        name: "Receiving Details",
        icon: (
          <ReactSVG
            className="svg-box"
            wrapper="span"
            src={receivedetailicon}
          />
        ),
        path: "/inventory-receiving-report",
      },
      {
        name: "Usage Details",
        icon: (
          <ReactSVG className="svg-box" wrapper="span" src={usagedetailicon} />
        ),
        path: "/usage-detail-report",
      },
      {
        name: "Transfer Details",
        icon: (
          <ReactSVG
            className="svg-box"
            wrapper="span"
            src={transferdetailicon}
          />
        ),
        path: "/transfer-detail-report",
      },
      {
        name: "Adjustment Details",
        icon: (
          <ReactSVG
            className="svg-box"
            wrapper="span"
            src={transferdetailicon}
          />
        ),
        path: "/adjustment-detail-report",
      },
      {
        name: "Reconciliation Report",
        icon: (
          <ReactSVG
            className="svg-box"
            wrapper="span"
            src={transferdetailicon}
          />
        ),
        path: "/reconciliation-report",
      },
      {
        name: "Recalled Item Inventory",
        icon: (
          <ReactSVG
            className="svg-box"
            wrapper="span"
            src={transferdetailicon}
          />
        ),
        path: "/recalled-inventory-report",
      },
      {
        name: "Providers List",
        icon: (
          <ReactSVG
            className="svg-box"
            wrapper="span"
            src={transferdetailicon}
          />
        ),
        path: "/providers-list",
      },
      {
        name: "Locations List",
        icon: (
          <ReactSVG
            className="svg-box"
            wrapper="span"
            src={transferdetailicon}
          />
        ),
        path: "/locations-list",
      },
      {
        name: "Users List",
        icon: (
          <ReactSVG
            className="svg-box"
            wrapper="span"
            src={transferdetailicon}
          />
        ),
        path: "/users-list",
      },
      {
        name: "Medications List",
        icon: (
          <ReactSVG
            className="svg-box"
            wrapper="span"
            src={transferdetailicon}
          />
        ),
        path: "/medications-list",
      },
      {
        name: "Lot Search Items",
        icon: (
          <ReactSVG
            className="svg-box"
            wrapper="span"
            src={transferdetailicon}
          />
        ),
        path: "/lot-search-item",
      },
      {
        name: "Lot Search Patients",
        icon: (
          <ReactSVG
            className="svg-box"
            wrapper="span"
            src={transferdetailicon}
          />
        ),
        path: "/lot-search-patients",
      },
      {
        name: "Ordering Product Report",
        icon: (
          <ReactSVG
            className="svg-box"
            wrapper="span"
            src={transferdetailicon}
          />
        ),
        path: "/ordering-product-report",
      },
       {
        name: "Ordering Product Inventory Report",
        icon: (
          <ReactSVG
            className="svg-box"
            wrapper="span"
            src={transferdetailicon}
          />
        ),
        path: "/ordering-product-inventory-report",
      },
    ],
  },
  {
    name: "AI Predictions",
    icon: <ReactSVG className="svg-box" wrapper="span" src={predictionicon} />,
    path: "",
    subMenus: [
      {
        name: "Forecasts",
        icon: <ReactSVG className="svg-box" wrapper="span" src={forcasticon} />,
        path: "/predictions",
      },
    ],
  },
  {
    name: "",
    path: "",
    subMenus: [
      {
        name: "User profile",
        path: "/user-profile",
        pages: [
          {
            name: "Edit",
            path: "/edit-profile-user",
          },
          {
            name: "View",
            path: "/user-profile",
          },
        ],
      },
    ],
  },
  {
    name: "Admin",
    icon: <ReactSVG className="svg-box" wrapper="span" src={usericon} />,
    path: "",
    subMenus: [
      {
        name: "User Management",
        icon: <ReactSVG className="svg-box" wrapper="span" src={usericon} />,
        path: "/users",
        pages: [
          {
            name: "Add User",
            path: "/create-user",
          },
          {
            name: "View User",
            path: "/view-user",
          },
          {
            name: "Edit User",
            path: "/edit-user",
          },
        ],
      },
      {
        name: "Location Master",
        icon: (
          <ReactSVG
            className="svg-box"
            wrapper="span"
            src={locationmastericon}
          />
        ),
        path: "/location-master",
        pages: [
          {
            name: "Add New",
            path: "/add-location",
          },
          {
            name: "Edit Location",
            path: "/edit-location",
          },
          {
            name: "View Location",
            path: "/view-location",
          },
        ],
      },
      {
        name: "Item Type Master",
        icon: (
          <ReactSVG className="svg-box" wrapper="span" src={itemtypeicon} />
        ),
        path: "/item-type-master",
        pages: [
          {
            name: "Add New",
            path: "/add-item-master",
          },
          {
            name: "Edit Item Type",
            path: "/edit-item-master",
          },
          {
            name: "View Item Type",
            path: "/view-item-master",
          },
        ],
      },
      {
        name: "Unit Master",
        icon: (
          <ReactSVG className="svg-box" wrapper="span" src={unitmastericon} />
        ),
        path: "/unit-master",
        pages: [
          {
            name: "Add New",
            path: "/add-unit",
          },
          {
            name: "Edit Unit",
            path: "/edit-unit",
          },
          {
            name: "View Unit",
            path: "/view-unit",
          },
        ],
      },
      {
        name: "Stock Master",
        icon: (
          <ReactSVG className="svg-box" wrapper="span" src={stockmastericon} />
        ),
        path: "/stock-master",
        pages: [
          {
            name: "Add New",
            path: "/add-stock",
          },
          {
            name: "Edit Stock",
            path: "/edit-stock",
          },
          {
            name: "View Stock",
            path: "/view-stock",
          },
        ],
      },
      {
        name: "Product Master",
        icon: (
          <ReactSVG
            className="svg-box"
            wrapper="span"
            src={productmastericon}
          />
        ),
        path: "/product-master",
        pages: [
          {
            name: "Add New",
            path: "/add-product-master",
          },
          {
            name: "View Product",
            path: "/view-product-master",
          },
          {
            name: "Edit Product",
            path: "/edit-product-master",
          },
        ],
      },
      {
        name: "Ordering Product Master",
        icon: (
          <ReactSVG
            className="svg-box"
            wrapper="span"
            src={orderingproducticon}
          />
        ),
        path: "/ordering-product-master",
        pages: [
          {
            name: "Add New",
            path: "/add-ordering-product",
          },
          {
            name: "View Ordering Product",
            path: "/view-ordering-product",
          },
          {
            name: "Edit Ordering Product",
            path: "/edit-ordering-product",
          },
        ],
      },
      {
        name: "Vendor Master",
        icon: (
          <ReactSVG className="svg-box" wrapper="span" src={vendormastericon} />
        ),
        path: "/vendor-master",
        pages: [
          {
            name: "Add New",
            path: "/add-vendor",
          },
          {
            name: "View Vendor",
            path: "/view-vendor",
          },
          {
            name: "Edit Vendor",
            path: "/edit-vendor",
          },
        ],
      },
      {
        name: "Patient Master",
        icon: (
          <ReactSVG
            className="svg-box"
            wrapper="span"
            src={patientmastericon}
          />
        ),
        path: "/patient-master",
        pages: [
          {
            name: "Add New",
            path: "/add-patient",
          },
          {
            name: "View Patient",
            path: "/view-patient/:id",
          },
          {
            name: "Edit Patient",
            path: "/edit-patient/:id",
          },
        ],
      },
      {
        name: "Roles & Permissions",
        icon: (
          <ReactSVG className="svg-box" wrapper="span" src={permissionicon} />
        ),
        path: "/roles-permissions",
        pages: [
          {
            name: "Add Role",
            path: "/add-role",
          },
          {
            name: "Edit Role",
            path: "/edit-role/:id",
          },
        ],
      },
      {
        name: "API Message Log",
        icon: <ReactSVG className="svg-box" wrapper="span" src={apimsgicon} />,
        path: "/api-message-log",
      },
      {
        name: "Settings",
        icon: <ReactSVG className="svg-box" wrapper="span" src={settingicon} />,
        path: "/settings",
        pages: [
          {
            name: "Add New",
            path: "/add-setting",
          },
          {
            name: "Edit Setting",
            path: "/edit-setting/:id",
          },
          {
            name: "View Setting",
            path: "/view-setting/:id",
          },
        ],
      },
      {
        name: "Athena Health Settings",
        icon: <ReactSVG className="svg-box" wrapper="span" src={settingicon} />,
        path: "/athena-settings",
      },
      {
        name: "Inventory Order",
        icon: (
          <ReactSVG
            className="svg-box"
            wrapper="span"
            src={inventoryordericon}
          />
        ),
        path: "/inventory-order",
        pages: [
          {
            name: "Inventory Order Detail",
            path: "/inventory-order-detail/:id",
          },
        ],
      },
      {
        name: "Usage Transactions",
        icon: (
          <ReactSVG className="svg-box" wrapper="span" src={usagetransicon} />
        ),
        path: "/usage-transactions",
        pages: [
          {
            name: "User Profile",
            path: "/usage-transactions-profile/:id",
          },
        ],
      },
      {
        name: "Item Warning Master",
        icon: (
          <ReactSVG className="svg-box" wrapper="span" src={itemwarningicon} />
        ),
        path: "/item-warning-master",
        pages: [
          {
            name: "Add New",
            path: "/add-item-warning",
          },
          {
            name: "View Warning Item",
            path: "/item-warning-profile/:id",
          },
          {
            name: "Edit Warning Item",
            path: "/edit-item-warning/:id",
          },
        ],
      },
      {
        name: "TR Report",
        icon: (
          <ReactSVG
            className="svg-box"
            wrapper="span"
            src={inventorystatusicon}
          />
        ),
        path: "/tr-report",
      },
      {
        name: "Label Setup",
        icon: (
          <ReactSVG className="svg-box" wrapper="span" src={contactusicon} />
        ),
        path: "/label-setup",
        pages: [
          {
            name: "Edit Label",
            path: "/edit-label/:id",
          },
          {
            name: "Add New",
            path: "/add-label",
          },
          {
            name: "View Label",
            path: "/label-details/:id",
          },
        ],
      },
    ],
  },
  {
    name: "Temperature Monitoring",
    icon: <ReactSVG className="svg-box" wrapper="span" src={predictionicon} />,
    path: "",
    subMenus: [
      {
        name: "Refrigerator Management",
        icon: <ReactSVG className="svg-box" wrapper="span" src={forcasticon} />,
        path: "/refrigerators",
        pages: [
          {
            name: "Add Refrigerator",
            path: "/add-refrigerator",
          },
          {
            name: "Edit Refrigerator",
            path: "/edit-refrigerator",
          }
        ],
      },
    ],
  },
  {
    name: "Support",
    icon: <ReactSVG className="svg-box" wrapper="span" src={userguideicon} />,
    path: "",
    subMenus: [
      {
        name: "User Guide",
        icon: (
          <ReactSVG className="svg-box" wrapper="span" src={userguideicon} />
        ),
        path: "/user-guide",
      },
      {
        name: "Contact Us",
        icon: (
          <ReactSVG className="svg-box" wrapper="span" src={contactusicon} />
        ),
        path: "/contact-us",
      },
      {
        name: "Tickets",
        icon: <ReactSVG className="svg-box" wrapper="span" src={ticketicon} />,
        path: "/tickets",
      },
      {
        name: "About Us",
        icon: <ReactSVG className="svg-box" wrapper="span" src={aboutusicon} />,
        path: "/about-us",
      },
    ],
  },
];
