import React from 'react'
import './AddRefrigerator.scss';
import { Button, Form } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from "react-select";

const testLocationOpts = [
  { value: '7 Hills Department (150)', label: '7 Hills Department (150)' },
  { value: '7 Hills Department (100)', label: '7 Hills Department (100)' },
  { value: '7 Hills Department (50)', label: '7 Hills Department (50)' }
];

const AddRefrigerator = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isEdit = Boolean(location.pathname === '/edit-refrigerator');

  return (
    <div className="add-refrigerator-wrapper">
      <div className="page-title">
        <h2>{isEdit ? "Edit" : "Add"} Refrigerator</h2>
      </div>
      <div className="add-refrigerator-box">
        <Form>
          <Form.Group className="mb-4">
            <Form.Label>
              Name<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Name"
              id="name"
              name="name"
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>
              Sensor Id<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Sensor Id"
              id="sensorId"
              name="sensor_id"
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>
              Low (Warm)<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Low (Warm)"
              id="low_warm"
              name="low_warm"
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>
              Low (Alert)<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Low (Alert)"
              id="low_alert"
              name="low_alert"
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>
              High (Warm)<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter High (Warm)"
              id="high_warm"
              name="high_warm"
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>
              High (Alert)<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter High (Alert)"
              id="high_alert"
              name="high_alert"
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>
              location<sup>*</sup>
            </Form.Label>
            <Select
              classNamePrefix="react-select"
              options={testLocationOpts}
              id="clinicalRoleId"
              name="clinicalRoleId"
              className={`${isEdit ? "is-invalid-border" : ""} react-select-container`}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>
              Alert Email Ids<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Alert Email Ids"
              id="alertEmailIds"
              name="alert_email_ids"
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>
              Alert phone Nos<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Alert phone Nos"
              id="alertPhoneNos"
              name="alert_phone_nos"
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <div className="item-checkbox-content">
              <Form.Check
                inline
                label="Active"
                type="checkbox"
                id="isActive"
                name="isActive"
              />
              <Form.Check
                inline
                label="Alert"
                type="checkbox"
                id="isAlert"
                name="isAlert"
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-4 full-width text-end">
            <Button
              style={{ marginRight: "10px" }}
              variant="outline-primary"
              onClick={() => {
                navigate("/refrigerators");
              }}
            >
              Cancel
            </Button>
            <Button type="submit">{isEdit ? "Update" : "Add"}</Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  )
}

export default AddRefrigerator;