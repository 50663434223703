import { useState } from "react";
import exporticon from "../../../assets/images/export-icon.svg";
import { ReactSVG } from "react-svg";
import { Button } from "react-bootstrap";
import Loading from "../../../components/LoadingPage/Loading";
import useReports from "../../../Hooks/useReports";
import moment from "moment";
import TelerikReporting from "../../../components/TelerikReporting/TelerikReporting";

const LotSearchPatients = () => {
  const [ndc, setNdc] = useState("");
  const [lot, setLot] = useState("");
  const [reportKey, setReportKey] = useState(0);
  const { lotSearchPatientsExportAPI, lotSearchPatientsExportLoading} = useReports();

  const onLotChange = (e: any) => {
    setLot(e.target.value);
  };
  const onNDCChange = (e: any) => {
    setNdc(e.target.value);
  };
   const getlotPatientsData = ()=>{
    setReportKey((prevKey) => prevKey + 1);
    }
  const cleearAll = () => {
      setLot("");
      setNdc("");
      getlotPatientsData();
  };

  const handleExport = async ()=>{
    const response = await lotSearchPatientsExportAPI({
      ndcCode: ndc,
      lotNo: lot
    });
    const url = URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${localStorage.getItem("profileClientId")?.toUpperCase() ?? ""}_Lot_Search_Patients_Report_${moment().format('YYYYMMDD_HHmmss')}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }
  return (
    <>
    
    <div className="report-content-wrapper" style={{ marginBottom: "20px" }}>
      <div className="report-filter-wrapper">
          { lotSearchPatientsExportLoading ?  <Loading /> : null}
        <div className="report-filter-item-wrapper">
          <div className="filter-item-box">
            <label className="form-label">NDC</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter NDC"
              value={ndc}
              onChange={onNDCChange}
            />
          </div>
          <div className="filter-item-box">
            <label className="form-label">Lot</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter lot no"
              value={lot}
              onChange={onLotChange}
            />
          </div>
          <div className="filter-item-box clear-all-box">
            <Button
              type="button"
              variant="primary"
              onClick={getlotPatientsData}
            >
              View
            </Button>
            <button type="button" className="clear-filter-btn"  onClick={cleearAll}>
              Clear All
            </button>
          </div>
        </div>
        <div className="report-export-box">
          <button type="button" className="report-export-btn" onClick={handleExport}>
            <ReactSVG className="svg-box" wrapper="span" src={exporticon} />{" "}
            Export
          </button>
        </div>
      </div>
    </div>
    {reportKey > 0 ? 
          <TelerikReporting
            key={reportKey}
            params={{
              ndcCode: ndc,
              lotNo: lot,
              isExport: "false",
            }}
            reportName="LotSearchPatientsReport.trdp"
            reportEndPoint="report/lotsearch/getpatients"
          /> 
        :
          ''
      }
    </>
  );
};

export default LotSearchPatients;
