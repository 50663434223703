import exporticon from "../../../assets/images/export-icon.svg";
import { ReactSVG } from "react-svg";
import { Button } from "react-bootstrap";
import { useState } from "react";
import useReports from "../../../Hooks/useReports";
import Loading from "../../../components/LoadingPage/Loading";
import moment from "moment";
import TelerikReporting from "../../../components/TelerikReporting/TelerikReporting";

const LotSearchItem = () => {
  const [ndc, setNdc] = useState("");
  const [lot, setLot] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [isIncludeZero, setIsIncludeZero] = useState(false);
  const [reportKey, setReportKey] = useState(0);
  const { lotSearchItemExportAPI,lotSearchItemExportLoading } = useReports();
  const onLotChange = (e: any) => {
    setLot(e.target.value);
  };
  const onNDCChange = (e: any) => {
    setNdc(e.target.value);
  };
  
  const getlotItemData = ()=>{
    setReportKey((prevKey) => prevKey + 1);
  }
  const cleearAll = () => {
      setLot("");
      setNdc("");
      setIsIncludeZero(false);
      setIsActive(true);
      getlotItemData();
  };

  const handleExport = async ()=>{
    const response = await lotSearchItemExportAPI({
      ndcCode: ndc,
      lotNo: lot,
      isActive: isActive,
      isZeroQty: isIncludeZero
    });
    const url = URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${localStorage.getItem("profileClientId")?.toUpperCase() ?? ""}_Lot_Search_Item_Report_${moment().format('YYYYMMDD_HHmmss')}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }

  return (
    <>
    <div className="report-content-wrapper" style={{ marginBottom: "20px" }}>
      <div className="report-filter-wrapper">
         { lotSearchItemExportLoading ?  <Loading /> : null}
        <div className="report-filter-item-wrapper">
          <div className="filter-item-box">
            <label className="form-label">NDC</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter NDC"
              value={ndc}
              onChange={onNDCChange}
            />
          </div>
          <div className="filter-item-box">
            <label className="form-label">Lot</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter lot no"
              value={lot}
              onChange={onLotChange}
            />
          </div>
          <div className="filter-item-box filter-checkbox-items">
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="checkbox" 
                checked={isActive}
                onChange={(e) => {
                  setIsActive(e.target.checked);
                }}
              />
              <label className="form-check-label">Active</label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="checkbox" 
               checked={isIncludeZero}
                onChange={(e) => {
                  setIsIncludeZero(e.target.checked);
                }}
              />
              <label className="form-check-label">Include 0 Quantity</label>
            </div>
          </div>
          <div className="filter-item-box clear-all-box">
             <Button
              type="button"
              variant="primary"
              onClick={getlotItemData}
            >
              View
            </Button>
            <button type="button" className="clear-filter-btn"  onClick={cleearAll}>
              Clear All
            </button>
          </div>
        </div>
        <div className="report-export-box">
              <button type="button" className="report-export-btn" onClick={handleExport}>
                <ReactSVG className="svg-box" wrapper="span" src={exporticon} />{" "}
                Export
              </button>
            </div>
      </div>
    </div>
    {reportKey > 0 ? 
          <TelerikReporting
            key={reportKey}
            params={{
              ndcCode: ndc,
              lotNo: lot,
              isActive: isActive,
              isZeroQty: isIncludeZero,
              isExport: "false",
            }}
            reportName="LotSearchItemsReport.trdp"
            reportEndPoint="report/lotsearch/getitems"
          /> 
        :
          ''
      }
    </>
  );
};

export default LotSearchItem;
