import React, { useState } from 'react';
import "./refrigeratorList.scss";
import CommonSearchOnly from '../../../components/commonSearchOnly/commonSearchOnly';
import { Dropdown, Table } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import editicon from "../../../assets/images/edit-icon.svg";
import deleteicon from "../../../assets/images/delete-icon.svg";
import threedotvertical from "../../../assets/images/three-dots-vertical.svg";
import { DeleteConfModel } from '../../../components/DeleteConfModel/deleteConfModel';
import { useNavigate } from 'react-router-dom';

const refrigeratorListData = [
  {
    "id": 1,
    "freezer_name": "C0001 - Cool Place 1",
    "location": "7 Hills Department (150)",
    "sensor_id": "ZS300-DLN233400006",
    "low_warn": 10.25,
    "low_alarm": 10,
    "high_warn": 25.75,
    "high_alarm": 26,
    "isAlert": "Yes",
    "isActive": "Yes"
  },
  {
    "id": 2,
    "freezer_name": "C0002 - Cool Place 2",
    "location": "7 Hills Department (150)",
    "sensor_id": "ZS300-DLN233400011",
    "low_warn": 20.22,
    "low_alarm": 20,
    "high_warn": 30.33,
    "high_alarm": 31,
    "isAlert": "Yes",
    "isActive": "Yes"
  },
  {
    "id": 3,
    "freezer_name": "C0003 - Cool Place 3",
    "location": "7 Hills Department (150)",
    "sensor_id": "ZS300-DLN233400234",
    "low_warn": 22.33,
    "low_alarm": 22,
    "high_warn": 38.55,
    "high_alarm": 39,
    "isAlert": "No",
    "isActive": "No"
  }
]

const RefrigeratorList = () => {
  const [isDltDialogOpen, setIsDltDialogOpen] = useState<boolean>(false);
  const [selectedRefrigerator, setSelectedRefrigerator] = useState<{ [key: string]: any }>({});
  const navigate = useNavigate();

  return (
    <div className="refrigerator-listing-wrapper">
      <CommonSearchOnly
        title="Refrigerators"
        buttonTitle="Add New"
        href="/add-refrigerator"
        disRecords={0}
        totalRecords={0}
        searchVal={''}
        setSearchVal={() => { }}
        defaultFilter={''}
      />
      <div className="listing-table-wrapper">
        <Table responsive="md" hover={true}>
          <thead>
            <tr>
              <th>Id</th>
              <th>Freezer Name</th>
              <th>Location</th>
              <th>Sensor Id</th>
              <th>Low (Warm)</th>
              <th>Low (Alarm)</th>
              <th>High (Warm)</th>
              <th>High (Alarm)</th>
              <th>Alert</th>
              <th>Active</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {refrigeratorListData.map(item => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.freezer_name}</td>
                <td>{item.location}</td>
                <td>{item.sensor_id}</td>
                <td>{item.low_warn}</td>
                <td>{item.low_alarm}</td>
                <td>{item.high_warn}</td>
                <td>{item.high_alarm}</td>
                <td>
                  <span className={`status-circle ${item.isAlert ? "active" : "inactive"}`}>
                    {item.isAlert ? "Yes" : "No"}
                  </span>
                </td>
                <td>
                  <span className={`status-circle ${item.isActive ? "active" : "inactive"}`}>
                    {item.isActive ? "Active" : "InActive"}
                  </span>
                </td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <ReactSVG
                        className="svg-box"
                        wrapper="span"
                        src={threedotvertical}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => navigate("/edit-refrigerator")}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={editicon}
                        />
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setIsDltDialogOpen(true);
                          setSelectedRefrigerator(item);
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={deleteicon}
                        />
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <DeleteConfModel
        showModal={isDltDialogOpen}
        toggleModal={() => setIsDltDialogOpen(false)}
        deleteAction={() => console.log("@@==> userDelete")}
        modelName={`Refrigerator`}
        recordName={selectedRefrigerator?.freezer_name || ""}
      />
    </div>
  )
}

export default RefrigeratorList;