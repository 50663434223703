import React, { useEffect, useState } from "react";
import ReportHeader from "../../../components/reportHeader/ReportHeader";
import useReports from "../../../Hooks/useReports";
import { toast } from "react-toastify";
import Loading from "../../../components/LoadingPage/Loading";
import { Button, Table } from "react-bootstrap";
import exporticon from "../../../assets/images/export-icon.svg";
import { ReactSVG } from "react-svg";
import Select from "react-select";
import useInventory from "./../../../Hooks/useInventory";
import { DropDownValues } from "../../../utils/types";
import useLocations from "../../../Hooks/useLocations";
import moment from "moment";
interface labelOption {
  value: string;
  label: string;
}

const OrderingProductReport = ()=>{
    const [orderingProductData, setOrderingProductData] = useState<any>({});
    const [orderId, setOrderId] = useState({value: "",label: "All"});
    const [selectedLoc, setSelectedLoc] = useState<labelOption>({value: "",label: "All"});
    const [orderingProdOptions, setOrderingProdOptions] = useState<DropDownValues[]>([]);
    const defaultFilter = { searchText: "", active: true, sortColumn: "" ,sortDirection: ""};
  const [searchFieldVal, setSearchFieldVal] = useState(defaultFilter);
    const [locationsList, setLocationsList] = useState<labelOption[]>([]);
    const { getOrderingProductReportAPI, 
      getOrderingProductReportLoading,
      orderingProductReportExportAPI,
      orderingProductReportExportLoading, } = useReports();
    const {  getOrderingProductsAPI, getOrderingProductsLoading} =  useInventory();
    const { getLocationsAPI, getLocationsLoading } = useLocations();
    const [reportKey, setReportKey] = useState(0);

    const getOrderingProductReportData = ()=>{
        getOrderingProductReportAPI({
            orderingProductId: orderId?.value,
            locationCode: selectedLoc?.value
        }).then((res) => {
            if(res?.result.length <= 0 && res?.status === 400){
               toast.error(res?.message);
               return;
            }else{
              setOrderingProductData(res?.result);
            }
        });
      }
    const getOrderingProducts = ()=>{
      getOrderingProductsAPI({ includeDeleted: false, active: true }).then(
      (res: any) => {
        if (res) {
          const options = res.map((option: any) => ({
              value: option.id,
              label: `${option.productNo} - ${option.productDescription} (${option.unit.unitCode})`,
            }));
           setOrderingProdOptions([{value: "",label: "All"},...options]);
        }
      });
    }
    const getLocations = () => {
    getLocationsAPI(searchFieldVal)
      .then((res: any) => {
        const options = res?.map((option: any) => ({
          value: option.locationCode,
          label: option.locationName,
        }));
        setLocationsList([{value: "",label: "All"},...options]);
      })
      .catch();
    };

    const cleearAll = () => {
      setOrderId({value: "",label: "All"});
      setSelectedLoc({value: "",label: "All"});
      setReportKey((prevKey) => prevKey + 1);
    };

    const handleExport = async ()=>{
      const response = await orderingProductReportExportAPI({
        orderingProductId: orderId?.value,
        locationCode: selectedLoc?.value
      });
      const url = URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${localStorage.getItem("profileClientId")?.toUpperCase() ?? ""}_Ordering_Product_Report_${moment().format('YYYYMMDD_HHmmss')}.xlsx`);
      document.body.appendChild(link);
      link.click();
    }

    useEffect(()=>{
      getLocations();
      getOrderingProducts();
      getOrderingProductReportData();
    },[]);
    

    useEffect (()=>{
      getOrderingProductReportData();
    },[reportKey]);
    return (
    <div className="report-content-wrapper">
      <ReportHeader
        reportTitle="Ordering Product Report"
        reportDate={orderingProductData?.subTitleReport ?? ""}
        srxImage={orderingProductData?.srxImage ?? ""}
        companyImage={orderingProductData?.clientImage ?? ""}
      />
      <div className="report-filter-wrapper">
        { getOrderingProductReportLoading || 
        getOrderingProductsLoading || 
        getLocationsLoading || 
        orderingProductReportExportLoading ?  <Loading /> : null}
        <div className="report-filter-item-wrapper">
          <div className="filter-item-box type-filter-item">
            <label className="form-label">Ordering Product</label>
            <Select 
              classNamePrefix="react-select"
              options={orderingProdOptions}
              value={orderingProdOptions?.find(
                (option: DropDownValues) =>
                 orderId?.value === option.value
              )}
              onChange={(option: any) => {
                 setOrderId(option);
              }}
            />
          </div>
          <div className="filter-item-box location-filter-item">
            <label className="form-label">Location</label>
            <Select 
              classNamePrefix="react-select"
              options={locationsList}
              value={locationsList?.find(
              (option: any) => selectedLoc?.value=== option.value
              )}
              onChange={(option: any) => {
              setSelectedLoc(option);
              }}
            />
          </div>
          <div className="filter-item-box clear-all-box">
             <Button
              type="button"
              variant="primary"
              onClick={getOrderingProductReportData}
            >
              View
            </Button>
            <button type="button" className="clear-filter-btn"  onClick={cleearAll}>
              Clear All
            </button>
          </div>
        </div>
        {orderingProductData && orderingProductData.length > 0 ? (
            <div className="report-export-box">
              <button type="button" className="report-export-btn" onClick={handleExport}>
                <ReactSVG className="svg-box" wrapper="span" src={exporticon} />{" "}
                Export
              </button>
            </div>
          ):
          ( 
            ""
          )
        }
      </div>
      <div className="filter-detail-wrapper">
        <div className="filter-item-detail-wrapper">
          <div className="filter-item-detail-title">
            {/* <h4>{orderingProductData?.titleReport ?? ""}</h4> */}
          </div>
          {
          orderingProductData && orderingProductData?.length > 0 ?
            <div className="filter-item-detail-table-wrapper">
            <Table bordered={false} hover={true}>
              <thead>
                <tr>
                  <th>PRODUCT NO.</th>
                  <th>DESCRIPTION</th>
                  <th>UOM</th>
                  <th>LOCATION</th>
                  <th>REORDER LEVEL</th>
                  <th>REORDER QTY</th>
                </tr>
              </thead>
              <tbody>
              {
                orderingProductData?.length > 0 && orderingProductData?.map((itemDetails:any)=>(
                  <tr>
                  <td>{itemDetails?.productNo}</td>
                  <td>{itemDetails?.description}</td>
                  <td>{itemDetails?.uom}</td>
                  <td>{itemDetails?.locationName}</td>
                  <td>{itemDetails?.reorderLevel}</td>
                  <td>{itemDetails?.reorderQty}</td>
                </tr>
                ))
              }
              </tbody>
            </Table>
          </div>
          :
           Object.keys(orderingProductData).length === 0 ? (
              ""
            ) : (
              <span className="d-flex justify-content-center align-items-center mt-5">
                Data not found..!
              </span>
            )
        }
        </div>
      </div>
    </div>
  );
}

export default OrderingProductReport;