import { getFilters } from "../../utils/commonUtils";
import { commonBaseApiSlice } from "../commonBaseApiSlice";
export interface TrList {
  trDateTime: string;
  userId: string;
  user: User;
  sessionRefNo: string;
  trType: string;
  ref1?: string;
  ref2?: string;
  ref3?: string;
  details?: string;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: any;
  createdBy: string;
  updatedBy: any;
}

export interface User {
  spSRXID: string;
  userName: string;
  password: string;
  title: any;
  fName: string;
  lName: string;
  mi: any;
  lastUsedDateAsHCP: string;
  toalCountAsHCP: number;
  lastUsedDateAsUser: string;
  toalCountAsUser: number;
  forceChangePassword: boolean;
  notes: any;
  deaNumber: string;
  email: any;
  userValue: number;
  clinicalRoleId: string;
  clinicalRole: any;
  emrId: any;
  roleId: string;
  role: any;
  refreshToken: string;
  refreshTokenExpiryTime: string;
  profilePicture: any;
  deletedBy: any;
  deleted: boolean;
  deletedOnUtc: any;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: any;
  createdBy: string;
  updatedBy: any;
}
export const reportsApiSlice = commonBaseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserslist: builder.mutation({
      query: () => ({
        url: "/reports/listofusers/get",
        method: "GET",
      }),
    }),
    trReportsDD: builder.mutation({
      query: () => ({
        url: "/trlogs/rendertrlogpage",
        method: "GET",
      }),
    }),
    trReports: builder.mutation({
      query: (data) => ({
        url: "/trlogs/get",
        method: "POST",
        body: data,
      }),
    }),
    inventoryStausExport: builder.mutation({
      query: (data)=>({
        url: "/reports/inventorystatus/export",
        method: "POST",
        body: data,
      }),
    }),
    inventoryExpireExport: builder.mutation({
      query: (data)=>({
        url: "/reports/expiredinventory/export",
        method: "POST",
        body: data,
      })
    }),
    inventoryReceiveDetailExport: builder.mutation({
      query: (data)=>({
        url: "/reports/inventoryreceivingdetails/export",
        method: "POST",
        body: data,
      })
    }),
    inventoryUsageDetailExport: builder.mutation({
      query: (data)=>({
        url: "/reports/inventoryusagedetails/export",
        method: "POST",
        body: data,
      })
    }),
    inventoryTranferDetailExport: builder.mutation({
      query: (data)=>({
        url: "/reports/inventorytransferdetails/export",
        method: "POST",
        body: data,
      })
    }),
    inventoryAdjustmentDetailExport: builder.mutation({
      query: (data)=>({
        url: "/reports/inventoryadjustmentdetails/export",
        method: "POST",
        body: data,
      })
    }),
    inventoryReconciliationExport: builder.mutation({
      query: (data)=>({
        url: "/reports/inventoryreconciliation/export",
        method: "POST",
        body: data,
      })
    }),
    inventoryRecalledItemExport: builder.mutation({
      query: (data)=>({
        url: "/reports/inventoryrecalleddetails/export",
        method: "POST",
        body: data,
      })
    }),
    providerListExport: builder.mutation({
      query: (data)=>({
        url: `/reports/listofproviders/export?${getFilters(data)}`,
        method: "GET",
      })
    }),
    userListExport: builder.mutation({
      query: (data)=>({
        url: `/reports/listofusers/export?${getFilters(data)}`,
        method: "GET",
      })
    }),
    medicationListExport: builder.mutation({
      query: (data)=>({
        url: "/reports/medicationslist/export",
        method: "GET",
        body: data,
      })
    }),
    getLotSearchItems: builder.mutation({
      query: (data)=>({
        url: "/report/lotsearch/getitems",
        method: "POST",
        body: data,
      })
    }),
    lotSearchItemExport: builder.mutation({
      query: (data)=>({
        url: "/report/lotsearch/exportitems",
        method: "POST",
        body: data,
      })
    }),
    lotSearchPatientsExport: builder.mutation({
      query: (data)=>({
        url: "/report/lotsearch/exportpatients",
        method: "POST",
        body: data,
      })
    }),
    getOrderingProductReport: builder.mutation({
      query: (data)=>({
        url: "/report/orderingproduct/get",
        method: "POST",
        body: data,
      })
    }),
    getOrderingProductInventoryReport: builder.mutation({
      query: (data)=>({
        url: "/report/orderingproduct/getinventory",
        method: "POST",
        body: data,
      })
    }),
    getQtyOnHandInventoryReport: builder.mutation({
      query: (data)=>({
        url: "/report/orderingproduct/getqtyonhand",
        method: "POST",
        body: data,
      })
    }),
    orderingProductReportExport : builder.mutation({
      query: (data)=>({
        url: "/report/orderingproduct/export",
        method: "POST",
        body: data,
      })
    }),
    orderingProductInventoryReportExport : builder.mutation({
      query: (data)=>({
        url: "/report/orderingproduct/exportinventory",
        method: "POST",
        body: data,
      })
    }),
  }),
});
export const {
  useGetUserslistMutation,
  useTrReportsDDMutation,
  useTrReportsMutation,
  useInventoryStausExportMutation,
  useGetLotSearchItemsMutation,
  useLotSearchItemExportMutation,
  useLotSearchPatientsExportMutation,
  useInventoryExpireExportMutation,
  useInventoryReceiveDetailExportMutation,
  useInventoryUsageDetailExportMutation,
  useInventoryTranferDetailExportMutation,
  useInventoryAdjustmentDetailExportMutation,
  useInventoryReconciliationExportMutation,
  useInventoryRecalledItemExportMutation,
  useProviderListExportMutation,
  useUserListExportMutation,
  useMedicationListExportMutation,
  useGetOrderingProductReportMutation,
  useGetOrderingProductInventoryReportMutation,
  useGetQtyOnHandInventoryReportMutation,
  useOrderingProductReportExportMutation,
  useOrderingProductInventoryReportExportMutation
} = reportsApiSlice;
