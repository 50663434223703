import React, { useEffect } from "react";
import ReportHeader from "../../../components/reportHeader/ReportHeader";
import { Table } from "react-bootstrap";

const QtyOnHandReport = ({qtyOnHandData}:any) =>{
    useEffect(() => {
    const element = document.getElementById('target-element');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' }); // Smooth scroll to the element
    }
  }, [qtyOnHandData]); 
    return(
    <div className="report-content-wrapper" style={{marginTop:"24px"}} id="target-element">
      <ReportHeader
        reportTitle="Qty On Hand"
      />
      <div className="filter-detail-wrapper">
        <div className="filter-item-detail-wrapper">
          <div className="filter-item-detail-title">
            {/* <h4>{orderingProductInventoryData?.titleReport ?? ""}</h4> */}
          </div>
          {
          qtyOnHandData && qtyOnHandData?.length > 0 ?
            // <div className="filter-item-detail-table-wrapper">
            <Table bordered={false} hover={true}>
              <thead>
                <tr>
                  <th>BARCODE</th>
                  <th>LOT NO.</th>
                  <th>STOCK</th>
                  <th>LOCATION</th>
                  <th>EXP DATE</th>
                  <th>QTY ON HAND</th>
                </tr>
              </thead>
              <tbody>
              {
                qtyOnHandData?.length > 0 && qtyOnHandData?.map((itemDetails:any)=>(
                  <tr
                    key={itemDetails?.orderingProductId}
                  >
                  <td>{itemDetails?.barcode}</td>
                  <td>{itemDetails?.lotNo}</td>
                  <td>{itemDetails?.sourceRcv}</td>
                  <td>{itemDetails?.locationName}</td>
                  <td>{itemDetails?.expiryDate}</td>
                  <td ref={(el) => {
                      if (el && itemDetails?.color) {
                        el.style.setProperty('color', itemDetails?.color.toLowerCase(), 'important');
                      }
                    }}
                  >{itemDetails?.qtyOnHand}</td>
                </tr>
                ))
            }
            {qtyOnHandData?.length > 0 && (
                <tr>
                    <td colSpan={5} style={{ textAlign: 'right', fontWeight: 'bold'}}>Total</td>
                    <td>{qtyOnHandData.reduce((total:any, item:any) => total + item?.qtyOnHand, 0)}</td>
                </tr>
            )}  
            </tbody>
            </Table>
        //   </div>
          :
           Object.keys(qtyOnHandData).length === 0 ? (
              ""
            ) : (
              <span className="d-flex justify-content-center align-items-center mt-5">
                Data not found..!
              </span>
            )
        }
        </div>
      </div>
    </div>
    );
} 
export default QtyOnHandReport;