import './contactus.scss';

const ContactUs = () => {
  return (
    <div className="contact-us-wrapper">
      <div className="page-title">
        <h2>Contact Us</h2>
      </div>
      <div className="container">
        <div className="row row-gap-4">
          <div className="col solid contact-form">
            <div className="card mb-2">
              <div className="card-body">
                <form>
                  <div className="mb-2">
                    <label className="form-label">Name</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="mb-2">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="mb-2">
                    <label className="form-label">Phone</label>
                    <input
                      type="tel"
                      name="phone"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="mb-2">
                    <label className="form-label">Message</label>
                    <textarea
                      name="message"
                      className="form-control"
                      rows={4}
                      required
                    ></textarea>
                  </div>
                  <button type="submit" className="btn btn-primary w-100">
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col contact-info-main">
            <div className="card mb-2">
              <div className="card-header">Address</div>
              <div className="card-body">
                <p className="card-text">991 US Highway 22, Suite 200, Bridgewater, NJ 08807</p>
              </div>
            </div>
            <div className="card mb-2">
              <div className="card-header">Phone</div>
              <div className="card-body">
                <p className="card-text"><a href="tel:+(908) 575-7304">(908) 575-7304</a></p>
              </div>
            </div>
            <div className="card">
              <div className="card-header">Email</div>
              <div className="card-body">
                <p className="card-text"><a href="mailto:support@trinetmedical.com">support@trinetmedical.com</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
