import React from "react";
import { Button, Dropdown, Table } from "react-bootstrap";
import threedotvertical from "../../../assets/images/three-dots-vertical.svg";
import deleteicon from "../../../assets/images/delete-icon.svg";
import downloadicon from "../../../assets/images/download-icon.svg";
import srxlogowithtext from "../../../assets/images/SRX-logo-with-text.svg";
import sflogo from "../../../assets/images/SourceFuse-logo.png";
import circlecheckicon from "../../../assets/images/circle-check-icon.svg";
import "./orderdetail.scss";
import { ReactSVG } from "react-svg";
import exporticon from "../../../assets/images/export-icon.svg";
import printicon from "../../../assets/images/print-icon.svg";
import { useEffect, useState } from "react";
import useInventoryOrder from "../../../Hooks/useInventoryOrder";
import { useParams } from "react-router-dom";
import Loading from "../../../components/LoadingPage/Loading";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import TelerikReporting from "../../../components/TelerikReporting/TelerikReporting";
const OrderDetail = () => {
  const { id } = useParams();
  const [orderdetails, setOrderDetails] = useState<any>();
  const [showReportModal, setShowReportModal] = useState(false);
  const [reportKey, setReportKey] = useState(0);
  const { getOrderItemsAPI,getOrderItemsLoading } = useInventoryOrder();
  const onCloseReportModal = () =>{
    setShowReportModal(false);
  }
  const onShowReportModal = () => {
    setShowReportModal(true);
    setReportKey((prevKey) => prevKey + 1);
  } 
  useEffect(() => {
    getOrderItemsAPI({ orderId:id }).then((res) => {
      if (res) {
        setOrderDetails(res[0]);
      }
    });
  }, []);
  
  return (
    <div className="order-page-wrapper">
      {getOrderItemsLoading ? <Loading /> : null}
      <div className="order-page-title-box">
        <h2>{orderdetails?.orderNo}</h2>
      </div>
      <div className="order-detail-box-wrapper">
        <div className="profile-detail-box">
          <div className="profile-info-box">
            <label>Order Date</label>
            <p>{moment(orderdetails?.orderDate).format('MM/DD/YYYY')}</p>
          </div>
          <div className="profile-info-box">
            <label>Active</label>
            <p>{orderdetails?.orderStatus ? "Yes" : "No"}</p>
          </div>
          <div className="profile-info-box">
            <label>Vendor Name</label>
            <p>{orderdetails?.vendorName}</p>
          </div>
          <div className="profile-info-box order-action-box">
            <button
              type="button"
              className="order-action-btn order-print-btn"
              onClick={onShowReportModal}
            >
            <ReactSVG
              className="svg-box"
              wrapper="span"
               src={printicon}
            />{" "}
              Print Order
            </button>
            <button
              type="button"
              className="order-action-btn report-export-btn"
            >
            <ReactSVG
              className="svg-box"
              wrapper="span"
              src={exporticon}
            />{" "}
              Export
            </button>
          </div>
        </div>
        <div className="inventory-order-table-wrapper">
          <div className="listing-table-wrapper">
            <Table responsive="md" hover={true}>
              <thead>
                <tr>
                  <th>Order No</th>
                  <th>Product No</th>
                  <th>Description</th>
                  <th>Location</th>
                  <th>Reorder Level</th>
                  <th>Quantity on Hand</th>
                  <th>Order Quantity</th>
                  <th>Unit</th>
                  <th>Status</th>
                  <th>Vendor Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  orderdetails?.reportData.length > 0 && orderdetails?.reportData.map((data:any)=>(
                    <tr>
                      <td>{data?.refNo}</td>
                      <td>{data?.productNo ?? "-"}</td>
                      <td>{data?.productDescription ?? "-"}</td>
                      <td>{data?.locationName ?? "-"}</td>
                      <td>{data?.reorderLevel ?? "-"}</td>
                      <td ref={(el) => {
                              if (el && data?.color) {
                                el.style.setProperty('color', data?.color.toLowerCase(), 'important');
                              }
                      }}>
                        {data.qtyOnHand ?? "-"}
                      </td>
                      <td>{data?.orderQty}</td>
                      <td>{data?.unitName}</td>
                      <td>
                        <span
                             className={
                              "status-circle " + (data?.status ? "active" : "inactive")
                          }
                          >
                            {data?.status ? "Active" : "InActive"}
                          </span>
                      </td>
                      <td>{data?.vendorName}</td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle variant="link">
                            <ReactSVG
                              className="svg-box"
                              wrapper="span"
                              src={threedotvertical}
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item href="#">
                              <ReactSVG
                                className="svg-box"
                                wrapper="span"
                                src={deleteicon}
                              />
                              Delete
                            </Dropdown.Item>
                            <Dropdown.Item href="#">
                              <ReactSVG
                                className="svg-box"
                                wrapper="span"
                                src={circlecheckicon}
                              />
                              Mark as Complete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </div>
        </div>
      </div>

       {/* Order Report Modal */}

      <Modal
        size="xl"
        id="trans-report-modal"
        show={showReportModal}
        onHide={onCloseReportModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4>Purchase Order</h4>
            <div className="trans-report-action-wrapper">
              {/* <div className="trans-report-action-box download-report-box">
                <ReactSVG
                  className="svg-box"
                  wrapper="span"
                  src={downloadicon}
                />
              </div>
              <div className="trans-report-action-box export-report-opt-box">
                <ReactSVG className="svg-box" wrapper="span" src={printicon} />
              </div> */}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="trans-report-content-wrapper">
            <div className="trans-report-content-header">
              <div className="header-content">
                <h5>{orderdetails?.titleReport}</h5>
              </div>
              <div className="header-logo-wrapper">
                <div className="header-logo-box srx-logo-box">
                  <img src={srxlogowithtext} alt="srx" />
                </div>
                <div className="header-logo-box sf-logo-box">
                  <img src={sflogo} alt="sourcefuse" />
                </div>
              </div>
            </div>
            <div className="trans-report-detail-box-wrapper">
              <h5>Order# : {orderdetails?.orderNo}</h5>
              <div className="trans-report-detail-box-container">
                <div className="trans-report-detail-box">
                  <label>Order Date</label>
                  <p>{moment(orderdetails?.orderDate).format("MM/DD/YYYY")}</p>
                </div>
                <div className="trans-report-detail-box">
                  <label>Order Status</label>
                  <p>{orderdetails?.orderStatus}</p>
                </div>
                <div className="trans-report-detail-box">
                  <label>Vendor Name</label>
                  <p>{orderdetails?.vendorName}</p>
                </div>
              </div>
              <div className="trans-report-table-wrapper">
               <TelerikReporting
                  key={reportKey}
                  params={{
                    orderId:id,
                  }}
                  reportName="OrderItemsReport.trdp"
                  reportEndPoint="inventoryorder/orderitems/get"
                />
              </div>
              <div className="profile-info-date"> 
                <p>Printing Date: {orderdetails?.rptPrintDate}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OrderDetail;
