import './about-us.scss';

const AboutUs = () => {
  return (
    <div className="about-us-wrapper">
      <div className="page-title">
        <h2>About Us</h2>
      </div>
      <div className="about-us-content-wrapper">
        <p>TriNet Medical has provided integrated software products, solutions, and consulting services to the Healthcare Electronic Medical/Health Records (EMR/EHR) industry since 2008. TriNet Medical’s success comes from its ability to help health care providers bridge their knowledge gaps in the areas of data management, application development and technology services</p>

        <p>Through a clear understanding of business needs and a culture of shared commitment, TriNet Medical helps its customers reduce operational costs, increase practice efficiency and patient safety, and improve the accuracy and compliance of their patients’ records.</p>

        <p>As a company, TriNet Medical values integrity, honesty, openness, excellence, continual self-improvement, and mutual respect. We take on challenges, and pride ourselves on seeing them through. We hold ourselves accountable to our customers, partners, and employees by honoring our commitments, providing results, and striving for the highest quality.</p>

        <p>TriNet Medical understands and embraces the idea that information technology will transform healthcare as we know it. We are dedicated to helping our customers—and the communities they serve—through this innovative digital technology transformation in the most seamless, simple, and cost-effective method possible. This dedication enables our users to see more clearly, know more fully and share more widely.</p>
      </div>
    </div>
  );
};

export default AboutUs;
