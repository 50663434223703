import { useEffect, useState } from "react";
import { Button, Dropdown, Form, Stack } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import "./settinglisting.scss";
import threedotvertical from "../../../assets/images/three-dots-vertical.svg";
import editicon from "../../../assets/images/edit-icon.svg";
import viewicon from "../../../assets/images/view-icon.svg";
import deleteicon from "../../../assets/images/delete-icon.svg";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import useSettings from "../../../Hooks/useSettings";
import Loading from "../../../components/LoadingPage/Loading";
import { ALLOWED_IMAGE_FILE_TYPES, MAX_IMAGE_FILE_SIZE, toggleClass } from "../../../utils/commonUtils";
import { DeleteConfModel } from "../../../components/DeleteConfModel/deleteConfModel";
import { Setting } from "../../../redux/settings/settingsSlice";
import CommonSearchOnly from "../../../components/commonSearchOnly/commonSearchOnly";
import CustomModal from "../../../components/customModal/CustomModal";
import uploadfileicon from "../../../assets/images/upload-file-icon.svg";
import { toast } from "react-toastify";

const Settings = () => {
  const navigate = useNavigate();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const { getSettingsAPI, getSettingsLoading,deleteSettingAPI, deleteSettingLoading, uploadCompanyLogoApi, uploadCompanyLogoLoading } = useSettings();
  const [showModal, setShowModal] = useState(false);
  const [showUploadImgModal, setShowUploadImgModal] = useState(false);
  const [settingsList, setSettingsList] = useState<Setting[]>([]);
  const [currentSetting, setCurrentSetting] = useState<Setting>();
  const [fileData, setFileData] = useState<any>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const defaultFilter = { searchText: "" };
  const [searchFieldVal, setSearchFieldVal] = useState(defaultFilter);

  const deleteSetting = async () => {
    await deleteSettingAPI({
      id: currentSetting?.id,
      updatedBy: currentUserId ?? "",
    });
    toggleModal();
    getSettings();
  };
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };

  const getSettings = () => {
    getSettingsAPI(searchFieldVal)
      .then((res: any) => setSettingsList(res))
      .catch();
  };
   const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      try{
        if(event.target.files){
          const selectedFile:any = event.target.files[0];
          if(!selectedFile){
            toast.error("Please upload a image.");
            setFileData([]);
            return;
          }
        if(!ALLOWED_IMAGE_FILE_TYPES.includes(selectedFile.type)){
          toast.error("Invalid file type. Only JPEG & PNG are allowed.");
          setFileData([]);
          return;
        }
        if (selectedFile.size > MAX_IMAGE_FILE_SIZE) {
          toast.error(`File is too large. Maximum allowed size is ${MAX_IMAGE_FILE_SIZE / 1024} KB.`);
          setFileData([]);// Clear the file
          return;
        }
         // If all validations pass, process the file
        const reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
          const result = event.target?.result;
          if (typeof result === "string") {
            const base64String = result;
            // Update the state with the selected file and base64 URL
            setFileData(selectedFile);
            setImageUrl(base64String);
          }
        };
  
        // Start reading the file as a Data URL
        reader.readAsDataURL(selectedFile);
      }
    }catch(e:any){
        toast.error(e.message);
      }
    };

  const toggleUploadLogoModal = () => {
    toggleClass();
    setShowUploadImgModal(!showUploadImgModal);
  };
  
  const handleUpload = () => {
      if (fileData) {
        try{
          const formDataConv:any = new FormData();
          formDataConv.append('file', fileData);
          uploadCompanyLogoApi(formDataConv).then((res:any) => {
              if(res){
                toast.success(res?.message);
                clearImg();
                // localStorage.setItem("profilePicture",res?.result?.profilePicture);
                toggleUploadLogoModal();
                navigate("/settings");
              }
            });
        }catch(e:any){
          toast.error("Company Logo Upload Unsuccesfully!");
        }finally{
           toggleUploadLogoModal();
        }
      }
  };
  const clearImg = () => {
    setFileData(null);
    setImageUrl(null);
  };
  useEffect(() => {
    getSettings();
  }, [searchFieldVal]);
  return (
    <div className="setting-listing-wrapper">
      {getSettingsLoading || deleteSettingLoading || uploadCompanyLogoLoading ? <Loading /> : null}
      <Stack direction="horizontal">
      <div style={{"width": "-webkit-fill-available"}}>
         <CommonSearchOnly
          title="Settings"
          buttonTitle="Add New"
          href="/add-setting"
          disRecords={settingsList.length | 0}
          totalRecords={settingsList.length | 0}
          searchVal={searchFieldVal}
          setSearchVal={setSearchFieldVal}
          defaultFilter={defaultFilter}
        />
      </div>
        <button type="button" className="upload-button" onClick={toggleUploadLogoModal}>
          Company Logo
        </button>
      </Stack>
      <div className="listing-table-wrapper">
        <Table responsive="md" hover={true}>
          <thead>
            <tr>
              <th>Setting Name</th>
              <th>Setting Value</th>
              <th>Category</th>
              <th>Notes</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {settingsList?.map((x: Setting) => (
              <tr
                key={x.id}
                onDoubleClick={() => navigate(`/view-setting/${x.id}`)}
              >
                <td title={x.settingName}>{x.settingName}</td>
                <td title={x.settingValue}>{x.settingValue}</td>
                <td title={x.category}>{x.category}</td>
                <td title={x.notes}>{x.notes}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <ReactSVG
                        className="svg-box"
                        wrapper="span"
                        src={threedotvertical}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href={`/view-setting/${x.id}`}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={viewicon}
                        />
                        View
                      </Dropdown.Item>
                      <Dropdown.Item href={`/edit-setting/${x.id}`}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={editicon}
                        />
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCurrentSetting(x);
                          toggleModal();
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={deleteicon}
                        />
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteSetting}
        recordName={currentSetting?.settingName || ""}
        modelName={`setting`}
      />
       {showUploadImgModal && (
        <CustomModal
          id="upload-avatar-modal"
          title="Change Report Logo"
          content={
            <>
              <div className="modal--content--wrapper">
                <div className="img-upload-box">
                  <Form.Control
                    type="file"
                    id="avatar-upload-input"
                    accept=".jpeg, .png"
                    onChange={handleFileChange}
                  />
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="Preview"
                      className="img-preview"
                      style={{ width: "200px", height: "200px" }}
                    />
                  ) : (
                    <>
                      <ReactSVG
                        className="svg-box"
                        wrapper="span"
                        src={uploadfileicon}
                      />
                      <p>Drag and Drop your image here or Click to Upload</p>
                    </>
                  )}
                </div>
                <div className="file-type-hint">
                  <span>File Type: JPEG, PNG</span>
                  <span
                    style={fileData && fileData?.size > MAX_IMAGE_FILE_SIZE ? { color: "red" } : {}}
                  >
                    Maximum Size: 500 KB
                  </span>
                </div>
              </div>
              <div className="modal--action--btn--wrapper">
                <Button
                  type="button"
                  variant="outline-primary"
                  onClick={() => {
                    clearImg();
                    toggleUploadLogoModal();
                  }}
                >
                  Cancel
                </Button>
                <Button type="button" variant="primary" onClick={handleUpload} disabled={fileData === null}>
                  Upload
                </Button>
              </div>
            </>
          }
          onClose={toggleUploadLogoModal}
        />
      )}
    </div>
  );
};

export default Settings;
